












import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

import HomeLoanCalculator from '@/components/Calculators/HomeLoanCalculator.vue';

@Component({
  components: {
    HomeLoanCalculator,
  },
})
export default class Calculators extends Mixins(View) {
  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scr-personal-loan-calculator-app') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://explorepropertywhitsunday.com.au/calc.js';
      scr.async = true;
      scr.defer = true;
      scr.id = 'scr-personal-loan-calculator-app';

      scr.onload = () => {
        console.log('Core Logic Integration Loaded');
      };

      document.body.append(scr);
    }, 500);
  }
}
